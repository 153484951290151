import React from "react";
import './ShareAppLink.scss';
// import txtLogo from '../../assets/images/damnTxt.png'
// import logo from '../../assets/images/logo_yellow.png'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    isAndroid,
    isIOS
} from "react-device-detect";
import googleappstore from '../../assets/images/Google_Play_Store.png'
import appstore from '../../assets/images/App_Store.png'
const logo = "https://res.cloudinary.com/dtzkljk1x/image/upload/v1611649515/Community-Club/Logo_Black_kpqcvk.png"

const AppLink = (props) => {
    let club_name;
    let ios_url;
    let android_url;
    let data
    if (isBrowser === true) {
        const project = () => {
            switch (props.match.params.clubidentifier) {

                case "grenta-fc": return { club_name: "Gretna FC", ios_url: "https://apps.apple.com/us/app/gretna-fc-2008-official-app/id1547784224", android_url: "https://play.google.com/store/apps/details?id=com.cc.gretnafc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1620981198/Community-Club/club-logo/mgcuafvm1slt6ripo31w.png" };
                case "greystone-rovers": return { club_name: "Greystone Rovers", ios_url: "https://apps.apple.com/us/app/greystone-rovers/id1574600729", android_url: "https://play.google.com/store/apps/details?id=com.cc.greystone", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1625126402/Community-Club/club-logo/lzpevglzzwtidartpepz.png" };
                case "threave-roversfc": return { club_name: "Threave RoversFC", ios_url: "https://apps.apple.com/us/app/threave-rovers-fc/id1582757107", android_url: "https://play.google.com/store/apps/details?id=com.cc.threaveroversfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1629373051/Community-Club/club-logo/Threave-Rovers-Logo_qmf1q4.png" };
                case "newtongrange-star": return { club_name: "Newtongrange Star", ios_url: "https://apps.apple.com/us/app/newtongrange-star/id1586115189", android_url: "https://play.google.com/store/apps/details?id=com.cc.newtongrange", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1631534852/Community-Club/club-logo/patrmbv7o8urczrldctn.png" };
                case "spartans-fc-youth": return { club_name: "Spartans FC Youth", ios_url: "https://apps.apple.com/us/app/spartans-fc-youth/id1593153042", android_url: "https://play.google.com/store/apps/details?id=com.cc.spartansfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1635740678/Community-Club/club-logo/nox8etx0nvbhcu4nuqdb.png" };
                case "lochryan-fc": return { club_name: "Lochryan Football Club", ios_url: "https://apps.apple.com/us/app/lochryan-boys-club/id1597892114", android_url: "https://play.google.com/store/apps/details?id=com.cc.lochryanboys", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1651650852/Community-Club/club-logo/amw1zoku7yuliihboea9.png" };

                case "hutchison-vale-fc": return { club_name: "Hutchison Vale FC", ios_url: "https://apps.apple.com/us/app/hutchison-vale-fc/id1603393507", android_url: "https://play.google.com/store/apps/details?id=com.cc.hutchisonfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1639560393/Community-Club/club-logo/sg6od07mppsh1lg3qaae.png" };
                case "haddington-athletic-fc": return { club_name: "Haddington Athletic FC", ios_url: "https://apps.apple.com/us/app/haddington-athletic/id1608340052", android_url: "https://play.google.com/store/apps/details?id=com.cc.haddingtonfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1642045731/Community-Club/club-logo/khw7jam8vfqkponrmagb.png" };
                case "northbank-fc": return { club_name: "Northbank FC", ios_url: "https://apps.apple.com/us/app/northbank-fc/id1610298458", android_url: "https://play.google.com/store/apps/details?id=com.cc.northbankfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1644895124/Community-Club/club-logo/q82hgm7gihycgyrfdmtb.png" };
                case "tranentcolts-fc": return { club_name: "TranentColts FC", ios_url: "https://apps.apple.com/us/app/tranent-colts-fc/id1625682293", android_url: "https://play.google.com/store/apps/details?id=com.cc.tranentcoltsfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/Community-Club/club-logo/lka0ibxfpc0pd3ripugg.png" };
                case "workington-afc": return { club_name: "Workington AFC", ios_url: "https://apps.apple.com/us/app/workington-afc/id1625663209", android_url: "https://play.google.com/store/apps/details?id=com.cc.workingtonafc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/Community-Club/club-logo/adpmhkls9tungxnppwi2.png" };
                case "cleator-moor-celtic-fc": return { club_name: "Cleator Moor Celtic FC", ios_url: 'https://apps.apple.com/us/app/cleator-moor-celtic-fc/id1631798430', android_url: 'https://play.google.com/store/apps/details?id=com.cc.cleatormoorfc', logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1655367934/Community-Club/club-logo/nxl4ul4f6dezfvibdcxp.png" };
                case "east-kilbride-utd": return { club_name: "East Kilbride Utd", ios_url: "https://apps.apple.com/us/app/itunes-connect/6444095997", android_url: "https://play.google.com/store/apps/details?id=com.cc.eastkilbride", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1664449264/Community-Club/club-logo/xtwpimdrkfqahqpcmwqz.png" };

                case "guru-nanak-fc": return {
                    club_name: "Guru Nanak Football Club",
                    ios_url: "https://apps.apple.com/th/app/guru-nanak-fc/id6473404670",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.gurunanakfc&pli=1",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1701944831/Community-Club/club-logo/guru-nanak-fcmggcw.png"
                };
                case "shrewsbury-up-and-comers-fc": return {
                    club_name: "Shrewsbury Up and Comers Football Club",
                    ios_url: "https://apps.apple.com/us/app/shrewsbury-up-comers-fc/id6472403417",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.shrewsburyfc",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1702629581/Community-Club/club-logo/shrewsbury-up-and-comers-z0eorxhjedamgqdpmbrw.png"
                };
                case "bradford-park-ave-fc": return {
                    club_name: "Bradford Park Ave Football Club",
                    ios_url: "https://apps.apple.com/us/app/bradford-park-ave-afc/id6467082824",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.bradfordparkavefc",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1698403697/Community-Club/club-logo/bradford-park-ave-fcxu3ntw.png"
                };
                case "leather-head-youth-fc": return {
                    club_name: "Leatherhead Youth FC",
                    ios_url: "https://apps.apple.com/pl/app/leatherhead-youth-fc/id6475956294",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.leatherheadyouthfc",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1706181057/Community-Club/club-logo/leather-head-youth-fcr4dg9c.png"
                };
                case "blackpool-wren-rovers-fc": return {
                    club_name: "Blackpool Wren Rovers FC",
                    ios_url: "https://apps.apple.com/dk/app/blackpool-wren-rovers-fc/id6476538018",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.blackpoolwrenroverfc",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1704976186/Community-Club/club-logo/blackpool-wren-rovers-fc.png"
                };
                case "chester-nomads-afc": return {
                    club_name: "Chester Nomads AFC",
                    ios_url: "https://apps.apple.com/gb/app/chester-nomads-afc/id6478266026",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.chesternomadsfc",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1708332151/Community-Club/club-logo/chester-nomads-afcou9w08.png"
                };
                case "west-kirby-fc": return {
                    club_name: "West Kirby FC",
                    ios_url: "https://apps.apple.com/us/app/west-kirby-fc/id6532595152",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.westkirbyfc",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1721966545/Community-Club/club-logo/west-kirby-fcx17hl.png"
                };
                case "thornton-cleveleys-fc": return {
                    club_name: "Thornton Cleveleys FC",
                    ios_url: "https://apps.apple.com/us/app/thornton-cleveleys-fc/id6608972990",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.thorntoncleveleysfc",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1722930631/Community-Club/club-logo/thornton-cleveleys-fcsr0asn.png"
                };
                case "ercall-colts-jfc": return {
                    club_name: "Ercall Colts JFC",
                    ios_url: "https://apps.apple.com/us/app/ercall-colts-jfc/id6736756451",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.ercallcoltsjfc",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1728038739/Community-Club/club-logo/ercall-colts-jfckvnu0o.png"
                };
                case "blue-brazil-fc": return {
                    club_name: "Blue Brazil FC",
                    ios_url: "https://apps.apple.com/us/app/blue-brazil-fc/id6737540841",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.bluebrazilfc",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1730094503/Community-Club/club-logo/blue-brazil-fcd4z6l.png"
                };
                case "barnton-afc": return {
                    club_name: "Barnton AFC",
                    ios_url: "https://apps.apple.com/us/app/barnton-afc/id6739402902",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.barntonafc",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1733818822/Community-Club/club-logo/barnton-afcnajkoa.png"
                };
                case "atherton-laburnum-rovers-fc": return {
                    club_name: "Atherton Laburnum Rovers FC",
                    ios_url: "https://apps.apple.com/vn/app/atherton-laburnum-rovers-fc/id6743152830?uo=2",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.athertonfc&hl=en_IN",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1741758427/Community-Club/club-logo/atherton-laburnum-rovers-fcaac46a.png"
                };
                default: return { club_name: "", ios_url: "", android_url: "", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1611649515/Community-Club/Logo_Black_kpqcvk.png" }
            }
        }
        data = project()
    } else {
        const project = () => {
            switch (props.match.params.clubidentifier) {

                case "grenta-fc": return { club_name: "Gretna FC", ios_url: "https://apps.apple.com/us/app/gretna-fc-2008-official-app/id1547784224", android_url: "https://play.google.com/store/apps/details?id=com.cc.gretnafc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1620981198/Community-Club/club-logo/mgcuafvm1slt6ripo31w.png" };
                case "greystone-rovers": return { club_name: "Greystone Rovers", ios_url: "https://apps.apple.com/us/app/greystone-rovers/id1574600729", android_url: "https://play.google.com/store/apps/details?id=com.cc.greystone", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1625126402/Community-Club/club-logo/lzpevglzzwtidartpepz.png" };
                case "threave-roversfc": return { club_name: "Threave RoversFC", ios_url: "https://apps.apple.com/us/app/threave-roversfc/id1582757107", android_url: "https://play.google.com/store/apps/details?id=com.cc.threaveroversfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1629373051/Community-Club/club-logo/Threave-Rovers-Logo_qmf1q4.png" };
                case "newtongrange-star": return { club_name: "Newtongrange Star", ios_url: "https://apps.apple.com/us/app/newtongrange-star/id1586115189", android_url: "https://play.google.com/store/apps/details?id=com.cc.newtongrange", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1631534852/Community-Club/club-logo/patrmbv7o8urczrldctn.png" };
                case "spartans-fc-youth": return { club_name: "Spartans FC Youth", ios_url: "https://apps.apple.com/us/app/spartans-fc-youth/id1593153042", android_url: "https://play.google.com/store/apps/details?id=com.cc.spartansfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1635740678/Community-Club/club-logo/nox8etx0nvbhcu4nuqdb.png" };
                case "lochryan-fc": return { club_name: "Lochryan Football Club", ios_url: "https://apps.apple.com/us/app/lochryan-boys-club/id1597892114", android_url: "https://play.google.com/store/apps/details?id=com.cc.lochryanboys", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1651650852/Community-Club/club-logo/amw1zoku7yuliihboea9.png" };

                case "hutchison-vale-fc": return { club_name: "Hutchison Vale FC", ios_url: "https://apps.apple.com/us/app/hutchison-vale-fc/id1603393507", android_url: "https://play.google.com/store/apps/details?id=com.cc.hutchisonfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1639560393/Community-Club/club-logo/sg6od07mppsh1lg3qaae.png" };
                case "haddington-athletic-fc": return { club_name: "Haddington Athletic FC", ios_url: "https://apps.apple.com/us/app/haddington-athletic/id1608340052", android_url: "https://play.google.com/store/apps/details?id=com.cc.haddingtonfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1642045731/Community-Club/club-logo/khw7jam8vfqkponrmagb.png" };
                case "northbank-fc": return { club_name: "Northbank FC", ios_url: "https://apps.apple.com/us/app/northbank-fc/id1610298458", android_url: "https://play.google.com/store/apps/details?id=com.cc.northbankfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1644895124/Community-Club/club-logo/q82hgm7gihycgyrfdmtb.png" };
                case "tranentcolts-fc": return { club_name: "TranentColts FC", ios_url: "https://apps.apple.com/us/app/tranent-colts-fc/id1625682293", android_url: "https://play.google.com/store/apps/details?id=com.cc.tranentcoltsfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/Community-Club/club-logo/lka0ibxfpc0pd3ripugg.png" };
                case "workington-afc": return { club_name: "Workington AFC", ios_url: "https://apps.apple.com/us/app/workington-afc/id1625663209", android_url: "https://play.google.com/store/apps/details?id=com.cc.workingtonafc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/Community-Club/club-logo/adpmhkls9tungxnppwi2.png" };
                case "cleator-moor-celtic-fc": return { club_name: "Cleator Moor Celtic FC", ios_url: 'https://apps.apple.com/us/app/cleator-moor-celtic-fc/id1631798430', android_url: 'https://play.google.com/store/apps/details?id=com.cc.cleatormoorfc', logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1655367934/Community-Club/club-logo/nxl4ul4f6dezfvibdcxp.png" };
                case "east-kilbride-utd": return { club_name: "East Kilbride Utd", ios_url: "https://apps.apple.com/us/app/itunes-connect/6444095997", android_url: "https://play.google.com/store/apps/details?id=com.cc.eastkilbride", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1664449264/Community-Club/club-logo/xtwpimdrkfqahqpcmwqz.png" };

                case "guru-nanak-fc": return {
                    club_name: "Guru Nanak Football Club",
                    ios_url: "https://apps.apple.com/th/app/guru-nanak-fc/id6473404670",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.gurunanakfc&pli=1",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1701944831/Community-Club/club-logo/guru-nanak-fcmggcw.png"
                };
                case "shrewsbury-up-and-comers-fc": return {
                    club_name: "Shrewsbury Up and Comers Football Club",
                    ios_url: "https://apps.apple.com/us/app/shrewsbury-up-comers-fc/id6472403417",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.shrewsburyfc",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1702629581/Community-Club/club-logo/shrewsbury-up-and-comers-z0eorxhjedamgqdpmbrw.png"
                };
                case "bradford-park-ave-fc": return {
                    club_name: "Bradford Park Ave Football Club",
                    ios_url: "https://apps.apple.com/us/app/bradford-park-ave-afc/id6467082824",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.bradfordparkavefc",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1698403697/Community-Club/club-logo/bradford-park-ave-fcxu3ntw.png"
                };

                case "leather-head-youth-fc": return {
                    club_name: "Leatherhead Youth FC",
                    ios_url: "https://apps.apple.com/pl/app/leatherhead-youth-fc/id6475956294",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.leatherheadyouthfc",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1706181057/Community-Club/club-logo/leather-head-youth-fcr4dg9c.png"
                };
                case "blackpool-wren-rovers-fc": return {
                    club_name: "Blackpool Wren Rovers FC",
                    ios_url: "https://apps.apple.com/dk/app/blackpool-wren-rovers-fc/id6476538018",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.blackpoolwrenroverfc",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1704976186/Community-Club/club-logo/blackpool-wren-rovers-fc.png"
                };
                case "chester-nomads-afc": return {
                    club_name: "Chester Nomads AFC",
                    ios_url: "https://apps.apple.com/gb/app/chester-nomads-afc/id6478266026",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.chesternomadsfc",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1708332151/Community-Club/club-logo/chester-nomads-afcou9w08.png"
                };
                case "west-kirby-fc": return {
                    club_name: "West Kirby FC",
                    ios_url: "https://apps.apple.com/us/app/west-kirby-fc/id6532595152",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.westkirbyfc",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1721966545/Community-Club/club-logo/west-kirby-fcx17hl.png"
                };
                case "thornton-cleveleys-fc": return {
                    club_name: "Thornton Cleveleys FC",
                    ios_url: "https://apps.apple.com/us/app/thornton-cleveleys-fc/id6608972990",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.thorntoncleveleysfc",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1722930631/Community-Club/club-logo/thornton-cleveleys-fcsr0asn.png"
                };
                case "ercall-colts-jfc": return {
                    club_name: "Ercall Colts JFC",
                    ios_url: "https://apps.apple.com/us/app/ercall-colts-jfc/id6736756451",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.ercallcoltsjfc",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1728038739/Community-Club/club-logo/ercall-colts-jfckvnu0o.png"
                };
                case "blue-brazil-fc": return {
                    club_name: "Blue Brazil FC",
                    ios_url: "https://apps.apple.com/us/app/blue-brazil-fc/id6737540841",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.bluebrazilfc",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1730094503/Community-Club/club-logo/blue-brazil-fcd4z6l.png"
                };
                case "barnton-afc": return {
                    club_name: "Barnton AFC",
                    ios_url: "https://apps.apple.com/us/app/barnton-afc/id6739402902",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.barntonafc",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1733818822/Community-Club/club-logo/barnton-afcnajkoa.png"
                };
                case "atherton-laburnum-rovers-fc": return {
                    club_name: "Atherton Laburnum Rovers FC",
                    ios_url: "https://apps.apple.com/vn/app/atherton-laburnum-rovers-fc/id6743152830?uo=2",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.athertonfc&hl=en_IN",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1741758427/Community-Club/club-logo/atherton-laburnum-rovers-fcaac46a.png"
                };
                default: return { club_name: "", ios_url: "", android_url: "", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1611649515/Community-Club/Logo_Black_kpqcvk.png" }
            }
        }
        data = project()
        switch (props.match.params.clubidentifier) {
            case "grenta-fc": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/gretna-fc-2008-official-app/id1547784224" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.gretnafc";
            case "greystone-rovers": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/greystone-rovers/id1574600729" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.greystone";
            case "threave-roversfc": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/threave-roversfc/id1582757107" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.threaveroversfc";
            case "newtongrange-star": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/newtongrange-star/id1586115189" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.newtongrange";
            case "spartans-fc-youth": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/spartans-fc-youth/id1593153042" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.spartansfc";
            case "lochryan-fc": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/lochryan-boys-club/id1597892114" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.lochryanboys";
            case "hutchison-vale-fc": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/hutchison-vale-fc/id1603393507" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.hutchisonfc";
            case "haddington-athletic-fc": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/haddington-athletic/id1608340052" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.haddingtonfc";
            case "northbank-fc": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/northbank-fc/id1610298458" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.northbankfc";
            case "tranentcolts-fc": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/tranent-colts-fc/id1625682293" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.tranentcoltsfc";
            case "workington-afc": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/workington-afc/id1625663209" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.workingtonafc";
            case "cleator-moor-celtic-fc": return isIOS === true ? window.location.href = 'https://apps.apple.com/us/app/cleator-moor-celtic-fc/id1631798430' : window.location.href = 'https://play.google.com/store/apps/details?id=com.cc.cleatormoorfc';
            case "east-kilbride-utd": return isIOS === true ? window.location.href = 'https://apps.apple.com/us/app/itunes-connect/6444095997' : window.location.href = 'https://play.google.com/store/apps/details?id=com.cc.eastkilbride';

            case "guru-nanak-fc": return isIOS === true ? window.location.href = "https://apps.apple.com/th/app/guru-nanak-fc/id6473404670" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.gurunanakfc&pli=1";

            case "shrewsbury-up-and-comers-fc": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/shrewsbury-up-comers-fc/id6472403417" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.shrewsburyfc";

            case "bradford-park-ave-fc": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/bradford-park-ave-afc/id6467082824" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.bradfordparkavefc";

            case "leather-head-youth-fc": return isIOS === true ? window.location.href = "https://apps.apple.com/pl/app/leatherhead-youth-fc/id6475956294" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.leatherheadyouthfc";
            
            case "blackpool-wren-rovers-fc": return isIOS === true ? window.location.href = "https://apps.apple.com/dk/app/blackpool-wren-rovers-fc/id6476538018" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.blackpoolwrenroverfc";
            
            case "chester-nomads-afc": return isIOS === true ? window.location.href = "https://apps.apple.com/gb/app/chester-nomads-afc/id6478266026" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.chesternomadsfc";
            
            case "west-kirby-fc": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/west-kirby-fc/id6532595152" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.westkirbyfc";
           
            case "thornton-cleveleys-fc": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/thornton-cleveleys-fc/id6608972990" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.thorntoncleveleysfc";
            
            case "ercall-colts-jfc": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/ercall-colts-jfc/id6736756451" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.ercallcoltsjfc";
             
            case "blue-brazil-fc": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/blue-brazil-fc/id6737540841" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.bluebrazilfc";

            case "barnton-afc": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/barnton-afc/id6739402902" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.barntonafc";

            case "atherton-laburnum-rovers-fc": return isIOS === true ? window.location.href = "https://apps.apple.com/vn/app/atherton-laburnum-rovers-fc/id6743152830?uo=2" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.athertonfc&hl=en_IN";

            default: return ""
        }
    }
    return (
        <div className='nodataFound'>
            <div className="backYellowarea">
            </div>
            <div className="upperLogo">
                <div className="mainImg">
                    <img src={data.logo} alt="txtlogo" fluid="true" />
                </div>
            </div>

            <div className="centerPart">
                <div className="imgSec">
                    < a href={data.android_url}><img src={googleappstore} alt="smallJogging" fluid="true" /></a>
                    < a href={data.ios_url}><img src={appstore} alt="smallJogging" fluid="true" /></a>
                </div>
            </div>


        </div>
    )
}



export default AppLink;